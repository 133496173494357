import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"

const CaseStudiesSlug = ({ data }) => {
  const { markdownRemark: markup } = data
  const { link, blurb } = markup.frontmatter

  return (
    <Layout>
      <section className="caseStudiesSlug py-3 py-lg-5">
        <div className="container">
          <div className="text-center py-3">
            <h2 className="section_heading py-3 text-uppercase">
              {markup.frontmatter.name}
            </h2>
            <p className="col-md">{markup.frontmatter.description}</p>
          </div>
          {blurb.length &&
            blurb.map((item, index) => (
              <div key={`caseStudies_${index}`}>
                <div className="col-12 col-lg-12">
                  <h4 className="text-uppercase text-muted sub_heading">
                    {item.heading}
                  </h4>
                </div>
                <div className={`row border-start mx-4`}>
                  <div className="col-12 col-lg-12 content p-3">
                    <ReactMarkdown children={item.content}></ReactMarkdown>
                  </div>
                  {item.image && (
                    <div className="col-12 col-lg-9 mb-3 ms-auto ">
                      <GatsbyImage
                        image={item.image.childImageSharp.gatsbyImageData}
                        alt={item.heading}
                      />
                    </div>
                  )}
                </div>
              </div>
            ))}

          {link && (
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn btn-primary"
            >
              Download
            </a>
          )}
        </div>
      </section>
    </Layout>
  )
}

export const CaseStudiesQuery = graphql`
  query CaseStudiesQueryByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        name
        description
        link
        blurb {
          heading
          image {
            childImageSharp {
              gatsbyImageData(quality: 100, layout: FULL_WIDTH)
            }
          }
          content
        }
      }
    }
  }
`
export default CaseStudiesSlug
